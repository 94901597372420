<template>
  <div class="box">
    <div>
      <div>
        <strong>用户信息</strong>
      </div>
      <div class="box2">
        <div class="box-text">用户名称：{{ accountInfo.name }}</div>
        <div class="box-text">登录名：{{ accountInfo.accountName }}</div>
      </div>
      <div class="box2">
        <div class="box-text">安全手机：{{ accountInfo.ifBind }}</div>
        <div class="box-text">登录密码：{{ accountInfo.ifPass }}</div>
      </div>
      <div class="box2">
        <div class="box-text">上次登录时间：{{ accountInfo.loginDate }}</div>
      </div>
    </div>
    <div>
      <div class="box-top-text">
        <strong>Accesskey信息</strong>
      </div>
      <div>
        <el-table :data="accessKeyDatas" border>
          <el-table-column prop="id" label="ID" min-width="3">
          </el-table-column>
          <el-table-column prop="secret" label="Secret" min-width="6">
          </el-table-column>
          <el-table-column prop="date" label="创建时间" min-width="6">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div>
      <div class="box-top-text">
        <strong>企业信息</strong>
      </div>
      <router-link
        to="/console/enterprisecertification"
        style="text-decoration: none"
        class="box2"
      >
        <el-row style="height: 30px; text-align: start">
          <el-link style="margin-top: 10px">待认证</el-link>
        </el-row>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      accountInfo: {
        name: 'name',
        accountName: 'accountName',
        ifPass: false,
        ifBind: true,
        contactPerson: '联系人',
        loginDate: '1970-01-01 00:00:00',
      },
      accessKeyDatas: [
        {
          id: 'id123',
          secret: 'secretfdasjogpasfodsg',
          date: '1970-01-01 00:00:00',
        },
      ],
      accessKeyColumns: [
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Secret',
          dataIndex: 'secret',
          key: 'secret',
        },
        {
          title: '创建时间',
          dataIndex: 'date',
          key: 'date',
        },
      ],
    };
  },
};
</script>

<style scoped>
.box {
  text-align: left;
  display: flex;
  flex-direction: column;
}
.box2 {
  display: flex;
  margin: 16px 0;
  margin-left: 60px;
}
.box-top-text {
  display: flex;
  margin: 16px 0;
}
.box-text {
  width: 300px;
}
</style>
